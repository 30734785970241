import React from 'react'
import Button from '../../components/common/Button.js'
import Header from '../../components/common/Header'
import Icon from '../../components/common/Icon.js'
import NetworkStats from '../../components/common/NetworkStats.js'
import DownloadCTA from '../../components/cta/DownloadCTA.js'
import GeneralCTA from '../../components/cta/GeneralCTA.js'
import Layout from '../../components/layout'
import Seo from '../../components/seo'

import { graphql } from 'gatsby'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import get from 'lodash/get'

class RootIndex extends React.Component {
  render() {
    const features = get(
      this,
      'props.data.allContentfulServiceDeliveryItems.nodes'
    )

    return (
      <Layout location={this.props.location}>
        <Seo
          title="Service Delivery | Urban Fox | EV Charging Network"
          description="We leverage Balfour Beatty’s unmatched scale, skill and
          capabilities to fully-fund, plan, install, operate and maintain
          large-scale EV charging networks across the UK."
        />
        <Header filter="Service Delivery" />
        <div className="bg-white flex flex-col max-w7-xl mx-auto px-6 gap-20  my-12 md:my-20">
          <div className="mx-auto max-w-2xl text-left md:text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Operating at scale
            </h2>
            <p className="mx-auto mt-6 text-left md:text-center max-w-xl text-lg leading-8 text-gray-600">
              We leverage Balfour Beatty’s unmatched scale, skill and
              capabilities to fully-fund, plan, install, operate and maintain
              large-scale EV charging networks across the UK.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <Button label="Contact us" href="/contact" />
            </div>
          </div>
          <div className="bg-white flex flex-col max-w-5xl mx-auto gap-20 my-6 md:my-12">
            <div className="mx-auto max-w-4xl text-left md:text-center">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                A strategic approach to EV charging infrastructure
              </h2>
              <p className="mx-auto mt-6 text-left md:text-center w-full text-lg leading-8 text-gray-600">
                Our goal is to deliver sustainable near-home public charging
                infrastructure. Download our latest white paper detailing our
                innovative commercial and contractual approach.
              </p>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <Button
                  label="Download now"
                  href="/request-download/whitepaper"
                />
              </div>
            </div>
          </div>
          <div className="mx-auto mt-4 max-w-2xl lg:max-w-4xl">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-6 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
              {features.map((feature) => (
                <div key={feature.name} className="relative pl-16">
                  <dt className="text-xl font-semibold leading-7 text-gray-900">
                    <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg text-white bg-uforange-600">
                      <Icon name={feature.icon} className="h-6 w-6" />
                    </div>
                    {feature.name}
                  </dt>
                  <dd className="mt-2 text-base leading-7 text-gray-600">
                    {feature.description?.raw &&
                      renderRichText(feature.description)}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
        <NetworkStats />
        <GeneralCTA />
        <DownloadCTA />
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query ServiceDeliveryItemQuery {
    allContentfulServiceDeliveryItems(sort: { fields: [order], order: ASC }) {
      nodes {
        title
        order
        icon
        description {
          raw
        }
      }
    }
  }
`
